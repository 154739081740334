<div class="modal-wrapper p-6" [formGroup]="resourceForm">
  <div class="modal-header d-flex justify-between">
    <div>
      <h2 class="mat-subtitle-1 mb-0 text">
        {{ isEditMode ? "Edit Resource" : "Add Resource" }}
      </h2>
      <p class="textXS mt-2 text">
        {{
          isEditMode
            ? "Enter details for edit Resource"
            : "Enter details for add Resource"
        }}
      </p>
    </div>
    <mat-icon class="pointer" (click)="close()">close</mat-icon>
  </div>
  <div class="content-wrapper my-4">
    <input formControlName="projectResourceID" type="hidden" />
    <div class="col s12 m6 l6">
      <div class="input-wrapper mb-4">
        <p class="mat-body-1 text mb-1 font-weight-500 input-label">
          Resource Name <span class="required-star">*</span>
        </p>
        <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
          [style.cursor]="isEditMode ? 'not-allowed' : 'pointer'"
        >
          <input
            appCapitalizeFirstLetter
            matInput
            placeholder="Enter here"
            formControlName="name"
            [readonly]="isEditMode"
            *ngIf="isEditMode; else addResourceInput"
            [style.cursor]="isEditMode ? 'not-allowed' : 'pointer'"
          />
          <ng-template #addResourceInput>
            <input
              matInput
              placeholder="Search and select"
              formControlName="name"
              [matAutocomplete]="autoResource"
              (input)="onResourceInput($event)"
            />
          </ng-template>
        </mat-form-field>
        <mat-autocomplete
          #autoResource="matAutocomplete"
          (optionSelected)="onResourceSelected($event)"
        >
          <mat-option
            *ngFor="let resource of filteredResources"
            [id]="resource.re_resourceID"
            [value]="resource.re_name"
          >
            {{ resource.re_name }}
          </mat-option>
        </mat-autocomplete>
        <span class="required-star" *ngIf="noResourcesFound"
          >No matching resources found.</span
        >
        <div *ngIf="errorMessage" class="required-star mt-2">
          <span *ngIf="resourceForm.get('name')?.value"
            >{{ resourceForm.get("name")?.value }},</span
          >
          <span> {{ errorMessage }} </span>
        </div>
      </div>
      <div class="col s12 m6 l6 mb-4">
        <p class="mat-body-1 text mb-1 input-label">
          Role<span class="required-star">*</span>
        </p>
        <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
          style="cursor: not-allowed"
        >
          <input
            matInput
            placeholder="--"
            formControlName="role"
            style="cursor: not-allowed"
          />
        </mat-form-field>
      </div>
      <div class="col s12 m6 l6 mb-4" *ngIf="costModal === 'hourly'">
        <p class="mat-body-1 text mb-1 input-label">
          Per Hour Rate
          <ng-container *ngIf="resourceForm.get('currency')?.value">
            ({{ resourceForm.get("currency")?.value }})
          </ng-container>
          <span class="required-star">*</span>
        </p>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <input
            matInput
            placeholder="Enter rate"
            formControlName="rate"
            required
            appNumericInput
            appNumericInput
            (numericInputValidityChange)="
              handleNumericInputValidityChange('perHourRate', $event)
            "
          />
        </mat-form-field>
        <span class="required-star" *ngIf="!numericInputValidity['perHourRate']"
          >Please enter a numeric value.</span
        >
        <span
          class="required-star"
          *ngIf="
            resourceForm.get('rate')?.invalid &&
            (resourceForm.get('rate')?.touched ||
              resourceForm.get('rate')?.dirty) &&
            numericInputValidity['perHourRate']
          "
          >Field is required.</span
        >
      </div>
      <div class="col s12 m6 l6 mb-4" *ngIf="costModal === 'daily'">
        <p class="mat-body-1 text mb-1 input-label">
          Per Day Rate
          <ng-container *ngIf="resourceForm.get('currency')?.value">
            ({{ resourceForm.get("currency")?.value }})
          </ng-container>
          <span class="required-star">*</span>
        </p>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <input
            matInput
            placeholder="Enter rate"
            formControlName="rate"
            required
            appNumericInput
            (numericInputValidityChange)="
              handleNumericInputValidityChange('perDayRate', $event)
            "
          />
        </mat-form-field>
        <span class="required-star" *ngIf="!numericInputValidity['perDayRate']"
          >Please enter a numeric value.</span
        >
        <span
          class="required-star"
          *ngIf="
            resourceForm.get('rate')?.invalid &&
            (resourceForm.get('rate')?.touched ||
              resourceForm.get('rate')?.dirty) &&
            numericInputValidity['perDayRate']
          "
          >Field is required.</span
        >
      </div>
      <!-- <div class="col s12 m6 l6 mb-4" *ngIf="costModal === 'fixed'">
        <p class="mat-body-1 text mb-1 input-label">
          Resource Salary / Per Month
          <span class="required-star">*</span>
        </p>
        <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
          style="cursor: not-allowed"
        >
          <input
            matInput
            placeholder="--"
            formControlName="salary"
            style="cursor: not-allowed"
          />
        </mat-form-field>
      </div> -->
      <div class="col s12 m6 l6 mb-4" *ngIf="costModal === 'hourly'">
        <p class="mat-body-1 text mb-1 input-label">
          Allocated Hours (Monthly) <span class="required-star">*</span>
        </p>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <input
            required
            matInput
            placeholder="Enter here"
            formControlName="allocatedHours"
            appNumericInput
            (numericInputValidityChange)="
              handleNumericInputValidityChange('allocatedHours', $event)
            "
          />
        </mat-form-field>
        <span
          class="required-star"
          *ngIf="!numericInputValidity['allocatedHours']"
          >Please enter a numeric value.</span
        >
        <span
          class="required-star"
          *ngIf="
            resourceForm.get('allocatedHours')?.invalid &&
            (resourceForm.get('allocatedHours')?.touched ||
              resourceForm.get('allocatedHours')?.dirty) &&
            numericInputValidity['allocatedHours']
          "
          >Field is required.</span
        >
      </div>
      <div class="col s12 m6 l6 mb-4" *ngIf="costModal === 'daily'">
        <p class="mat-body-1 text mb-1 input-label">
          Allocated Days (Monthly) <span class="required-star">*</span>
        </p>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <input
            required
            matInput
            placeholder="Enter here"
            formControlName="allocatedHours"
            appNumericInput
            (numericInputValidityChange)="
              handleNumericInputValidityChange('allocatedDays', $event)
            "
          />
        </mat-form-field>
        <span
          class="required-star"
          *ngIf="!numericInputValidity['allocatedDays']"
          >Please enter a numeric value.</span
        >
        <span
          class="required-star"
          *ngIf="
            resourceForm.get('allocatedHours')?.invalid &&
            (resourceForm.get('allocatedHours')?.touched ||
              resourceForm.get('allocatedHours')?.dirty) &&
            numericInputValidity['allocatedDays']
          "
          >Field is required.</span
        >
      </div>
      <!-- <div class="input-wrapper mb-4">
        <p class="mat-body-1 text mb-1 font-weight-500 input-label">
          Currency<span class="required-star">*</span>
        </p>
        <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
          [style.cursor]="isEditMode ? 'not-allowed' : 'pointer'"
        >
          <mat-select
            placeholder="Select"
            formControlName="currency"
            (selectionChange)="onCurrencyChange()"
          >
            <ng-container *ngIf="selectedResourceId; else onlyAccountCurrency">
              <ng-container
                *ngIf="currency === accountCurrency; else bothCurrencies"
              >
                <mat-option [value]="currency">{{ currency }}</mat-option>
              </ng-container>
              <ng-template #bothCurrencies>
                <mat-option
                  *ngFor="let curr of [currency, accountCurrency]"
                  [value]="curr"
                >
                  {{ curr }}
                </mat-option>
              </ng-template>
            </ng-container>
            <ng-template #onlyAccountCurrency>
              <mat-option [value]="accountCurrency">{{
                accountCurrency
              }}</mat-option>
            </ng-template>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="currencyErrorMessage" class="required-star mb-2">
        {{ currencyErrorMessage }}
      </div> -->
      <div class="col s12 m6 l6">
        <div class="input-wrapper">
          <p class="mat-body-1 text mb-1 input-label">
            Start Date <span class="required-star">*</span>
          </p>
          <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <input
              matInput
              [matDatepicker]="picker"
              placeholder="dd/mm/yyyy"
              formControlName="startDate"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <span
            class="required-star"
            *ngIf="
              resourceForm.get('startDate')?.invalid &&
              (resourceForm.get('startDate')?.touched ||
                resourceForm.get('startDate')?.dirty)
            "
            >Field is required.</span
          >
        </div>
      </div>
    </div>
    <div class="modal-footer mt-2 d-flex justify-end">
      <sft-button
        [style.cursor]="isButtonDisabled ? 'not-allowed' : 'pointer'"
        [label]="isEditMode ? 'Update' : 'Save'"
        [color]="'primary'"
        [variant]="'flat'"
        [disableRipple]="false"
        [size]="'large'"
        [disable]="isButtonDisabled"
        (click)="isButtonDisabled ? null : onSubmit()"
      ></sft-button>
    </div>
  </div>
</div>
