import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { ProjectService } from 'src/app/admin-portal/modules/project/services/project.service';
import { ResourceService } from 'src/app/admin-portal/modules/resource/services/resource.service';

@Component({
  selector: 'app-dialog-editresource',
  templateUrl: './project-resource-dialog-account-detail.component.html',
})
export class ProjectResourceDialogAccountDetailsComponent {
  resourceForm!: FormGroup;
  isEditMode: boolean = false;
  costModal: string = '';
  projectId: string = '';
  resource: any[] = [];
  resourceList: any[] = [];
  projectList: any[] = [];
  isButtonDisabled: boolean = true;
  errorMessage: string = '';
  currencyErrorMessage: string | null = null;
  isSubmitting: boolean = false;
  filteredResources: any[] = [];
  noResourcesFound: boolean = false;
  selectedResourceId: string = '';
  pageNumber: number = 0;
  limit: number = 25;
  search: string = '';
  sortBy: 'createdAt' = 'createdAt';
  orderBy: 'ASC' | 'DESC' = 'ASC';
  element: any;
  currency: string | undefined;
  accountCurrency?: string;
  isNumericInputValid: boolean = true;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    public matDialog: MatDialogRef<ProjectResourceDialogAccountDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private resourceService: ResourceService,
    private globalService: GlobalService,
    private projectService: ProjectService
  ) {}

  @ViewChild('autoResource') autoResource!: MatAutocomplete;

  ngOnInit(): void {
    // this.globalService.getProjectId().subscribe((projectId) => {
    //   this.projectId = projectId;
    // });
    this.isEditMode = this.data.isEditMode;
    this.costModal = this.data.costModal;
    this.initializeForm();

    this.accountCurrency = this.globalService.getCurrency();
    this.resourceForm.get('accountcurrency')?.setValue(this.accountCurrency);

    this.element = this.data.element;

    if (this.isEditMode && this.data.element) {
      this.patchFormValues(this.data.element);
    }

    this.currency = this.element?.currency;
    this.resourceForm.get('currency')?.setValue(this.currency);

    this.resourceForm.valueChanges.subscribe(() => {
      this.isButtonDisabled =
        this.resourceForm.invalid || !this.resourceForm.dirty;
    });

    this.resourceForm
      .get('name')
      ?.valueChanges.pipe(debounceTime(300), takeUntil(this.onDestroy$))
      .subscribe((searchText: string) => {
        if (searchText && searchText.length >= 1) {
          this.loadResourceName(
            this.pageNumber,
            this.limit,
            searchText,
            'createdAt',
            'ASC'
          );
        } else {
          this.filteredResources = [];
        }
      });

    this.isButtonDisabled = true;
    this.resourceForm.get('currency')?.setValue(this.currency);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  patchFormValues(element: any) {
    if (element && element.resource) {
      this.resourceForm.patchValue({
        name: element.resource.name,
        role: element.resource.role,
        rate: element.rate,
        salary: element.salary,
        startDate: element.startDate,
        projectResourceID: element.projectResourceID,
        currency: element.currency,
        allocatedHours: element.allocatedTime,
      });
    }
  }

  initializeForm(): void {
    this.resourceForm = this.formBuilder.group({
      name: ['', Validators.required],
      role: ['', Validators.required],
      startDate: ['', Validators.required],
      rate: [''],
      salary: [''],
      allocatedHours: [''],
      projectId: [''],
      projectResourceID: [''],
      currency: [''],
    });

    this.resourceForm.get('role')?.disable();
    this.resourceForm.get('salary')?.disable();
    if (this.isEditMode) {
      this.resourceForm.get('name')?.disable();
      this.resourceForm.get('currency')?.disable();
    }
  }

  loadResourceName(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: 'createdAt',
    orderBy: 'ASC' | 'DESC'
  ): void {
    this.resourceService
      .getResourceList(pageNumber, limit, search, sortBy, orderBy)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.resourceList = data?.data?.records;
          this.currency = data?.data?.records[0]?.currency;
          this.filteredResources = this.resourceList.slice();
        },
        error: (error: any) => {
          console.error('Error in fetching resource list:', error);
        },
      });
  }

  onResourceInput(event: any): void {
    const searchText: string = (event.srcElement.value || '').toLowerCase();
    if (searchText) {
      this.filteredResources = this.resourceList.filter((resource) => {
        return resource.name.toLowerCase().includes(searchText);
      });
      this.noResourcesFound = this.filteredResources.length === 0;
    } else {
      this.filteredResources = [];
      this.noResourcesFound = false;
    }
  }

  onResourceSelected(event: any): void {
    const selectedResourceName = event.option.id;
    const selectedResource = this.resourceList.find(
      (resource) => resource.re_resourceID === selectedResourceName
    );

    if (selectedResource) {
      this.selectedResourceId = selectedResource.re_resourceID;
      this.resourceForm.patchValue({
        salary: selectedResource.re_salary,
        role: selectedResource.re_role,
        currency: selectedResource.re_currency,
      });
      this.resourceForm.get('currency')?.setValue(selectedResource.currency);

      if (selectedResource.currency === this.accountCurrency) {
        this.currencyErrorMessage = '';
      } else {
        this.currencyErrorMessage =
          'Resource Currency and Account Currency do not match. Please select another currency.';
      }

      this.noResourcesFound = false;
    }
  }

  onCurrencyChange() {
    const selectedCurrency = this.resourceForm.get('currency')?.value;
    if (selectedCurrency !== this.accountCurrency) {
      this.currencyErrorMessage =
        'Selected currency does not match the account currency.';
    } else {
      this.currencyErrorMessage = null;
    }
  }

  onSubmit(): void {
    if (this.resourceForm.valid && !this.isSubmitting) {
      this.isSubmitting = true;
      const formData = this.resourceForm.getRawValue();

      const rateAsString = formData.rate.toString();

      const resourceData: {
        rate: string;
        salary: any;
        startDate: any;
        projectResourceID: any;
      } = {
        rate: rateAsString,
        salary: formData.salary,
        startDate: formData.startDate,
        projectResourceID: formData.projectResourceID,
      };

      if (this.isEditMode) {
        resourceData.projectResourceID = this.element.projectResourceID;
        this.projectService
          .updateProjectResource(resourceData)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: () => this.matDialog.close({ success: true }),
            error: (error: any) => {
              this.errorMessage =
                error?.error?.message ||
                'An error occurred while updating the resource. Please try again later.';
            },
          });
      } else {
        const createResourceData: {
          name: any;
          rate: string;
          role: any;
          allocatedTime: any;
          resourceID?: any;
          projectId?: any;
          startDate?: any;
          salary?: any;
          currency?: any;
        } = {
          name: formData.name,
          rate: rateAsString,
          allocatedTime: formData.allocatedHours,
          role: formData.role,
          startDate: formData.startDate,
          salary: formData.salary,
          currency: formData.currency,
          projectId: this.element,
          resourceID: this.selectedResourceId,
        };
        this.projectService
          .createProjectResource(createResourceData)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: () => this.matDialog.close({ success: true }),
            error: (errorResponse: any) => {
              console.error('Error creating core team:', errorResponse);
              this.errorMessage = this.formatErrorMessage(errorResponse);
              this.isSubmitting = false;
            },
          });
      }
    } else {
      console.error('Invalid form data.');
    }
  }

  private formatErrorMessage(error: any): string {
    let errorMessage =
      typeof error === 'string' ? error : error.message || 'An error occurred';
    errorMessage = errorMessage.replace(/^Error:\s*/, '');
    errorMessage = errorMessage.replace(/^"|"$/g, '');
    return errorMessage;
  }

  onSubmissionSuccess(): void {
    this.isSubmitting = false;
    this.matDialog.close({ success: true });
  }

  onSubmissionError(error: any): void {
    this.isSubmitting = false;
    console.error('Error submitting form:', error);
  }

  numericInputValidity: { [key: string]: boolean } = {
    perHourRate: true,
    perDayRate: true,
    allocatedDays: true,
    allocatedHours: true,
  };

  handleNumericInputValidityChange(field: string, validity: boolean) {
    this.numericInputValidity[field] = validity;
  }

  areAllNumericInputsValid(): boolean {
    return Object.values(this.numericInputValidity).every((valid) => valid);
  }

  close() {
    this.matDialog.close();
  }
}
